import {Protected, ScheduleBar} from "@/components";
import {useCallback, useEffect, useState} from "react";
import {useSchedule} from "@/services";
import Lottie from "react-lottie-player";
import CheckList from "@/assets/lottie/checklist.json"
import {useTranslation} from "react-i18next";
import {Button, Stack} from "react-bootstrap";
import {ISchedule} from "@/models";
import style from '@/styles/Home.module.css'
export default function Home() {

    const {t} = useTranslation();
    const {lastScheduled} = useSchedule();
    const dayFormatter = Intl.DateTimeFormat('pt-BR', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }).format
    const hourFormatter = Intl.DateTimeFormat('pt-BR', {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    }).format

    const [hasSchedule, setHasSchedule] = useState<ISchedule | null | undefined>();

    const render = useCallback(() => {
        if (hasSchedule === undefined) {
            return <></>
        } else if (!hasSchedule) {
            return <ScheduleBar/>
        }
        return <Stack className="content">
            <Lottie
                play
                loop
                animationData={CheckList}
            />
            <p className={style.text}>{t("already_taken") + dayFormatter(hasSchedule.date) + " às " + hourFormatter(hasSchedule.date)}</p>
            <Button href={hasSchedule.link} target="_blank">{t("go_to_page")}</Button>
        </Stack>
    }, [hasSchedule, t, dayFormatter, hourFormatter])

    useEffect(() => {
        lastScheduled().then(setHasSchedule).catch(() => setHasSchedule(undefined))
    }, [lastScheduled])

    return <Protected>
        {render()}
    </Protected>
}