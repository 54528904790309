import Home from "@/pages/Home";
import Login from "@/pages/Login";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ListSchedule from "@/pages/ListSchedule";

export default function Pages() {
    return <BrowserRouter>
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/" element={<Home/>}/>
            <Route path="/my-schedule" element={<ListSchedule/>}/>
        </Routes>
    </BrowserRouter>
}