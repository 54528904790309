import {ISchedule, Status} from "@/models/ISchedule";

const baseURL = "https://service.docway.com.br";

export class DocWay {
    constructor(
        private clientId: string,
        private clientSecret: string,
        private isStage: boolean = false
    ) {
    }

    private async authenticate(): Promise<{
        "access_token": string,
        "expires_in": number,
        "token_type": string
    }> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        const body = new URLSearchParams();
        body.append("grant_type", "client_credentials");
        body.append("scope", "api1");
        body.append("client_id", this.clientId);
        body.append("client_secret", this.clientSecret);

        const response = await fetch(`${baseURL}/${this.isStage ? 'stage-' : ''}auth/connect/token`, {
            method: 'POST',
            headers,
            body
        });
        return await response.json()
    }

    async listSchedule(date: Date): Promise<string[]> {
        const {access_token} = await this.authenticate();
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${access_token}`);
        const response = await fetch(`${baseURL}/${this.isStage ? 'stage-' : ''}appointment/api/appointments/video/availability`, {
            method: 'GET',
            headers,
            redirect: 'follow'
        })
        const available: string[] = await response.json()
        return available.filter(element => {
            let tmpDate = new Date(element);
            return tmpDate.getDate() === date.getDate() &&
                tmpDate.getMonth() === date.getMonth() &&
                tmpDate.getFullYear() === date.getFullYear()
        })
    }

    async schedule(date: Date, patientID: string, phone: string) {
        const {access_token} = await this.authenticate();

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${access_token}`);

        const body = JSON.stringify({
            "dateAppointment": date.toISOString(),
            "buyerId": patientID,
            "address": {
                "street": "",
                "number": "",
                "state": "",
                "city": "",
                "cep": "",
                "neighborhood": "",
                "country": "",
                "latitude": 0,
                "longitude": 0,
                "fullAddress": ""
            },
            "specialty": {
                "id": 1
            },
            "type": 6,
            "contactCode": "+55",
            "contactNumber": phone.replace("+55", ""),
            "reason": "Motivo de video",
            "partnerId": 1481,
            "symptoms": [],
            "gop": "oi"

        });

        const response = await fetch(`${baseURL}/${this.isStage ? 'stage-' : ''}appointment/api/patients/${patientID}/appointments`, {
            method: 'POST',
            headers,
            body,
            redirect: 'follow'
        })
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    }

    async createUser(body: {
        "name": string,
        "email": string,
        "phoneNumber": string,
        "countryCode": string,
        "sexo": string,
        "cpf": string
    }) {
        const {access_token} = await this.authenticate();
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${access_token}`);
        const response = await fetch(`${baseURL}/${this.isStage ? 'stage-' : ''}client/api/patients`, {
            method: 'POST',
            headers,
            redirect: 'follow',
            body: JSON.stringify(body)
        })
        let {id} = await response.json()
        return id as string
    }

    async cancelSchedule(scheduleID: number) {
        const {access_token} = await this.authenticate();

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${access_token}`);

        const response = await fetch(`${baseURL}/${this.isStage ? 'stage-' : ''}appointment/api/appointments/${scheduleID}/cancel`, {
            method: 'POST',
            headers,
            redirect: 'follow',
            body: JSON.stringify({
                cancelreason: ""
            })
        })
        await response.json()
    }


    async getSchedule(scheduleID: number) {
        const {access_token} = await this.authenticate();

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${access_token}`);

        const response = await fetch(`${baseURL}/${this.isStage ? 'stage-' : ''}appointment/api/appointments/${scheduleID}/details`, {
            method: 'GET',
            headers,
            redirect: 'follow'
        })
        const data = await response.json()
        const statusKey: number = data.status;
        return {
            date: new Date(data.dateAppointment),
            status: Status[statusKey],
            link: data.videoRoomLink
        } as ISchedule
    }
}