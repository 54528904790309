import {Button, ButtonToolbar} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import style from '@/styles/MenuBar.module.css'
import {AiOutlineCalendar, AiOutlineSchedule} from "react-icons/ai";
import {useCallback} from "react";

export default function MenuBar() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavigation = useCallback((path: string) => {
            navigate(path)
    }, [navigate])


    return <ButtonToolbar className={style.navbar} aria-label="Navbar toolbar">
        <Button aria-selected={location.pathname === '/'} variant={'outline-primary'} className={style.button} onClick={() => handleNavigation('/')}>
            <AiOutlineSchedule/>
            {t('schedule_option')}
        </Button>
        <Button aria-selected={location.pathname === '/my-schedule'} variant={'outline-primary'} className={style.button} onClick={() => handleNavigation('/my-schedule')}>
            <AiOutlineCalendar/>
            {t('listschedule')}
        </Button>
    </ButtonToolbar>
}