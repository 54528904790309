import {ReactComponent as Logo} from '@/assets/logo.svg';
import {LoginForm} from '@/components';
import {useAuthentication} from '@/services';
import style from '@/styles/Login.module.css';
import {useEffect} from 'react';
import {Container} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

export default function Login() {
    const navigate = useNavigate();
    const {IsAuthenticated} = useAuthentication()
    useEffect(() => {
        const checkAuth = async () => {
            IsAuthenticated().then((isLogged) => {
                if (isLogged) {
                    navigate('/')
                }
            })
        }
        checkAuth().then(console.log).catch(console.error)
    }, [IsAuthenticated, navigate])

    return <Container className={style.container}>
        <Logo className={style.logo}/>
            <LoginForm
                onSuccess={() => {
                    navigate('/')
                }}/>
        <span className={style.footer}>
            Powered by Docway
        </span>
    </Container>
}