export const Status = {
    1: "CREATED", // Pedido criado
    6: "IN_PROGRESS", // Pedido em andamento
    7: "COMPLETED", // Pedido finalizado
    8: "CANCELED_BY_SYSTEM", // Pedido cancelado pelo sistema
    9: "PRE_SCHEDULED", // Pedido pré-agendado
    10: "CANCELED_BY_PATIENT", // Pedido cancelado pelo paciente
    11: "CANCELED_BY_DOCTOR", // Pedido cancelado pelo médico
    12: "NO_SHOW", // Paciente não compareceu
} as { [key: number]: string };

export interface ISchedule {
    date: Date;
    id: number;
    status?: string;
    link?: string;
}
