import {useCallback, useEffect, useState} from "react"
import {Navigate, useLocation} from "react-router-dom";
import {useAuthentication} from "@/services";
import {ReactComponent as Logo} from '@/assets/logo.svg';
import {MenuBar} from "@/components";
import {Navbar} from 'react-bootstrap'
import {AiOutlinePoweroff} from "react-icons/ai";
import {useNavigate} from 'react-router-dom'
interface IProtectedProps {
    children: JSX.Element | JSX.Element[]
}

export default function Protected({children}: IProtectedProps) {
    const location = useLocation()
    const navigate = useNavigate()
    const {IsAuthenticated, SignOut} = useAuthentication()
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

    useEffect(() => {
        (async () => {
            setIsAuthenticated(await IsAuthenticated())
        })()
    })

    const handleSignOut = useCallback(()=>{
        SignOut().then(()=>{
            navigate('/login')
        })
    },[SignOut, navigate])

    if (isAuthenticated === false) {
        return <Navigate to={"/login"} state={{from: location}}/>
    } else if (isAuthenticated === true) {
        return <>
            <Navbar style={{
                background:"#fff"
            }}>
                <Logo className='logo'/>
                <AiOutlinePoweroff onClick={handleSignOut} className='exitbutton'/>
            </Navbar>
            {children}
            <MenuBar/>
        </>
    } else {
        return <></>
    }
}