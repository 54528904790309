import {Button, ButtonToolbar, Modal, Stack} from 'react-bootstrap';
import style from '@/styles/ScheduleBar.module.css'
import Calendar from "react-calendar";
import '@/styles/Calendar.css';
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Lottie from 'react-lottie-player';
import {useSchedule} from "@/services";
import {useTranslation} from "react-i18next";
import {AiOutlineCalendar, AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import Picker from 'react-mobile-picker'
import SuccessAnimation from '@/assets/lottie/confirmation.json';
import ErrorAnimation from '@/assets/lottie/error.json'
import {useNavigate} from "react-router-dom";

interface IConfirmModalProps {
    selectedDate?: Date;
    show: boolean;
    close: () => any;
    confirm: () => any;
}

interface IResultModalProps {
    success?: boolean;
    close: () => any;
}

function ResultModal({success, close}: IResultModalProps) {
    const navigate = useNavigate()
    return <Modal style={{
        zIndex: 10003
    }} show={success !== undefined} fullscreen>
        <Lottie
            loop={false}
            onComplete={() => {
                if (success === true) {
                    navigate('/my-schedule', {state: {forceServer: true}})
                } else if (success === false) {
                    close()
                }
            }}
            play
            animationData={success ? SuccessAnimation : ErrorAnimation}
        />
    </Modal>
}

function ConfirmModal({selectedDate, show, close, confirm}: IConfirmModalProps) {
    const {t} = useTranslation();
    const formatter = Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        hour12: false
    }).format
    return <Modal style={{
        zIndex: 10003
    }} fullscreen onHide={close} show={show}>
        <Modal.Header>
            <Modal.Title>{t('select_modal_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                !!selectedDate ?
                    `${t('select_modal_body')} ${formatter(selectedDate)}?` :
                    t('no_date_selected')
            }
        </Modal.Body>
        <Modal.Footer className={style.modal_footer}>
            <ButtonToolbar>
                <Button className={style.modal_button} onClick={close} variant={"outline-primary"}>
                    <AiOutlineClose/>
                    {t('cancel')}
                </Button>
                {
                    !!selectedDate &&
                    <Button className={style.modal_button} onClick={confirm} variant='primary'>
                        <AiOutlineCheck/>
                        {t('confirm')}
                    </Button>
                }
            </ButtonToolbar>
        </Modal.Footer>
    </Modal>

}

export default function ScheduleBar() {
    const formatter = Intl.DateTimeFormat('pt-BR', {
        weekday: "short"
    }).format

    /**
     * refs used
     */
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    /**
     * states used
     */
    const [hoursList, setHoursList] = useState<{ hourList: string[] }>({hourList: []});
    const [valueGroup, setValueGroup] = useState({hourList: ""});

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [cachedList, setCachedList] = useState<string[]>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [confirmationStatus, setConfirmationStatus] = useState<undefined | boolean>()

    /**
     * custom hooks used
     */
    const {t} = useTranslation();
    const {listHourly, schedule} = useSchedule()
    const maxDate = useMemo(() => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        return now;
    }, [])
    /**
     * callbacks
     */

    const updateHours = useCallback((date: Date) => {
        const hourFormatter = Intl.DateTimeFormat('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).format;
        listHourly(date).then(list => {
            setCachedList(list);
            let data = list.map(e => hourFormatter(new Date(e)))
            setHoursList({
                hourList: data
            })
        }).catch((err) => {
            console.error(err)
        })
    },[listHourly])

    const handleChange = useCallback((name: string, value: string) => {
        if (!value) {
            return
        }
        setValueGroup({
            hourList: value
        })
    }, [])

    const handleClickDay = useCallback((day: Date) => {
        day.setHours(0, 0, 0, 0);
        setSelectedDate(day)
        updateHours(day)
    }, [updateHours])

    const handleConfirm = useCallback(() => {
        const selectedHour = Intl.DateTimeFormat('pt-BR', {hour:'2-digit', minute:'2-digit'}).format(selectedDate);
        const selectedValue:string = cachedList.find(e=>e.match(selectedHour))??""
        buttonRef.current?.setAttribute('disabled', 'true')
        setShowModal(false);
        schedule(selectedValue)
            .then(() => setConfirmationStatus(true))
            .catch(() => setConfirmationStatus(false))
        buttonRef.current?.removeAttribute('disabled')
    }, [cachedList, schedule, selectedDate])

    const handleButtonClick = useCallback(() => {
        let hour = !!valueGroup.hourList ? valueGroup?.hourList?.split(':') : hoursList.hourList[0].split(':')
        let date = selectedDate;
        date.setHours(+hour[0], +hour[1])
        setSelectedDate(date)
        setShowModal(true);
    }, [hoursList.hourList, selectedDate, valueGroup.hourList])

    useEffect(() => {
        updateHours(new Date())
    }, [updateHours])

    return <Stack className="content">
        <h1 className={style.title}>{t('schedule_title')}</h1>
        <Calendar
            formatShortWeekday={(locale, date) => {
                return (formatter(date) as string)[0].toUpperCase()
            }}
            defaultValue={new Date()}
            minDate={new Date()}
            goToRangeStartOnSelect={false}
            maxDetail='month'
            minDetail='decade'
            returnValue='start'
            showNavigation={true}
            showNeighboringMonth={true}
            maxDate={maxDate}
            onClickDay={handleClickDay}
        />
        <Picker
            wheel={"normal"}
            valueGroups={valueGroup}
            optionGroups={hoursList}
            height={150}
            onChange={handleChange}
        />
        <Button ref={buttonRef} onClick={handleButtonClick} className={style.button}>
            <AiOutlineCalendar/>
            <span>
               {t('schedule')}
            </span>
        </Button>
        <ConfirmModal confirm={handleConfirm}
                      close={() => setShowModal(false)}
                      show={showModal}
                      selectedDate={selectedDate ? selectedDate : undefined}/>
        <ResultModal
            close={() => setConfirmationStatus(undefined)}
            success={confirmationStatus}/>
    </Stack>
}