import {Accordion, Stack, ToggleButton, ToggleButtonGroup} from 'react-bootstrap'
import BookingCard from "@/components/BookingCard";
import {useSchedule} from "@/services";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ISchedule} from "@/models";
import {Status} from "@/models/ISchedule";
import {useTranslation} from "react-i18next";
import Lottie from "react-lottie-player";
import Loading from '@/assets/lottie/loading.json'
import Empty from '@/assets/lottie/empty.json'
import {useLocation} from "react-router-dom";
import style from '@/styles/ScheduleList.module.css'
import {SchedulesContext} from "@/context";

export default function ScheduleList() {
    const location = useLocation()
    const {t} = useTranslation()
    const {listSchedule} = useSchedule()
    const [schedules, setSchedules] = useState<ISchedule[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selectedKey, setSelectedKey] = useState<string>('SCHEDULED');

    useEffect(() => {
        listSchedule().then((schedules) => {
            setSchedules(schedules);
            setIsLoading(false);
        }).catch(console.error)
    }, [listSchedule, location.state?.forceServer])

    const filters: { [key: string]: ISchedule[] } = useMemo(() => ({
        SCHEDULED: schedules.filter(
            schedule => schedule.status === Status[1] ||
                schedule.status === Status[6] ||
                schedule.status === Status[9]
        ),
        CANCELED: schedules.filter(
            schedule => schedule.status === Status[8] ||
                schedule.status === Status[10] ||
                schedule.status === Status[11]
        ),
        DONE: schedules.filter(
            schedule => schedule.status === Status[7] ||
                schedule.status === Status[12]
        )
    }), [schedules]);


    const renderSchedules = useCallback(() => {
        if (!filters[selectedKey].length) {
            return <Lottie
                loop
                play
                animationData={Empty}
            />
        }
        let badgeBg: string = 'primary';
        if (selectedKey === 'DONE') {
            badgeBg = 'success'
        } else if (selectedKey === 'CANCELED') {
            badgeBg = 'danger'
        }
        return filters[selectedKey].map((schedule, index) => <BookingCard
            badgeBg={badgeBg}
            badgeTitle={t(selectedKey)}
            eventKey={index.toString()}
            schedule={schedule}/>)
    }, [filters, selectedKey, t])


    return <SchedulesContext.Provider value={[schedules, setSchedules]}>
        <Stack className='content'>
            <h1 className={style.title}>
                {t('listschedule_title')}
            </h1>
            <ToggleButtonGroup className={style.toolbar} name='filter' type='radio' defaultValue={0}>
                {
                    Object.keys(filters).map((key, index) => (
                        <ToggleButton
                            key={index}
                            id={`${key}${index}`}
                            type='radio'
                            variant='outline-primary'
                            name='radio'
                            value={index}
                            onClick={() => setSelectedKey(key)}
                        >
                            {t(key)}
                        </ToggleButton>
                    ))
                }
            </ToggleButtonGroup>
            {
                isLoading ? <Lottie
                    loop
                    play
                    animationData={Loading}
                /> : <Accordion className={style.accordion}>
                    {renderSchedules()}
                </Accordion>
            }
        </Stack>
    </SchedulesContext.Provider>
}
