import {Accordion, Badge, Button, ButtonToolbar, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {AiOutlineCheck, AiOutlineClose, AiOutlineCustomerService, AiOutlineDelete} from "react-icons/ai";
import {ISchedule} from "@/models";
import {useSchedule} from "@/services";
import React, {useCallback, useContext, useState} from "react";
import style from '@/styles/BookingCard.module.css'
import {SchedulesContext} from "@/context";
import {Status} from "@/models/ISchedule";
import Lottie from "react-lottie-player";
import SuccessAnimation from "@/assets/lottie/confirmation.json";
import ErrorAnimation from "@/assets/lottie/error.json";


interface IBookingCardProps {
    schedule: ISchedule;
    eventKey: string;
    badgeBg: string;
    badgeTitle: string;
}

interface ICancelModalProps {
    show: boolean;
    close: () => any;
    confirm: () => any;
}

interface IResultModalProps {
    success?: boolean;
    close: () => any;
}

function ResultModal({success, close}: IResultModalProps) {
    return <Modal show={success !== undefined} fullscreen>
        <Lottie
            loop={false}
            onComplete={() => {
                close()
            }}
            play
            animationData={success ? SuccessAnimation : ErrorAnimation}
        />
    </Modal>
}

function CancelModal({show, close, confirm}: ICancelModalProps) {
    const {t} = useTranslation();
    return <Modal fullscreen onHide={close} show={show}>
        <Modal.Header>
            <Modal.Title>{t('cancel_modal_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {t('cancel_text')}
        </Modal.Body>
        <Modal.Footer className={style.modal_footer}>
            <ButtonToolbar>
                <Button className={style.modal_button} onClick={close} variant={"outline-primary"}>
                    <AiOutlineClose/>
                    {t('exit')}
                </Button>
                <Button className={style.modal_button} onClick={confirm} variant='primary'>
                    <AiOutlineCheck/>
                    {t('continue_cancel')}
                </Button>
            </ButtonToolbar>
        </Modal.Footer>
    </Modal>

}

export default function BookingCard({badgeTitle, badgeBg, eventKey, schedule: {id, date, link}}: IBookingCardProps) {
    const {t} = useTranslation();
    const {cancelSchedule} = useSchedule();
    const schedulesContext = useContext(SchedulesContext);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [confirmationStatus, setConfirmationStatus] = useState<undefined | boolean>()

    const dateFormat = Intl.DateTimeFormat('pt-BR', {
        weekday: 'long',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    }).format
    const hourFormat = Intl.DateTimeFormat('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }).format

    const handleCancelConfirm = useCallback(() => {
        cancelSchedule(id).then(() => {
            setConfirmationStatus(true);
            if (schedulesContext !== null) {
                const schedules = schedulesContext[0];
                const setSchedules = schedulesContext[1];
                const index = schedules.findIndex((element) => element.id === id);
                schedules[index].status = Status[10];
                setSchedules(Array.from(schedules));
            }
        }).catch(() => {
            setConfirmationStatus(false);
        }).finally(() => setShowModal(false))
    }, [cancelSchedule, id, schedulesContext])

    const handleCancel = useCallback(() => {
        setShowModal(true);
    }, [])

    const handleLink = useCallback(() => {
        if (!!link) {
            window.open(link, '_blank')
        }
    }, [link])

    return <Accordion.Item className={style['booking-card']} eventKey={eventKey}>
        <Accordion.Header className={style['booking-card-header']}>
            {dateFormat(date)}
        </Accordion.Header>
        <Accordion.Body className={style['booking-card-body']}>
            <Row className={style['booking-card-row']}>
                <span>
                    {hourFormat(date)}
                    <Badge className={style.badge} bg={badgeBg}>{badgeTitle}</Badge>
                </span>
            </Row>
            <Row className={style['booking-card-row']}>
                {
                    badgeTitle === t('SCHEDULED') && <>
                        <Button className={style['booking-card-buttons']} onClick={handleLink}>
                            <AiOutlineCustomerService/>
                            {t('go_to_page')}
                        </Button>
                        <Button className={style['booking-card-buttons']} onClick={handleCancel} variant='outline-danger'>
                            <AiOutlineDelete/>
                            {t('cancel')}
                        </Button>
                    </>
                }

            </Row>
        </Accordion.Body>
        <CancelModal
            show={showModal}
            close={() => setShowModal(false)}
            confirm={handleCancelConfirm}/>
        <ResultModal
            close={() => setConfirmationStatus(undefined)}
            success={confirmationStatus}/>
    </Accordion.Item>

}