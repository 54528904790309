import {useCallback, useState} from "react";
import {Amplify, Auth} from 'aws-amplify'
import * as process from "process";

Amplify.configure({
    'Auth': {
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USERPOOL,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
        authenticationFlowType: "USER_PASSWORD_AUTH"
    }
})


export default function useAuthentication() {

    const [data, setData] = useState<null | any>(null);

    const Request = useCallback(async (
        email: string
    ) => {
        setData(await Auth.signIn(email));
    }, []);


    const SignIn = useCallback(async (email: string, password: string, isCode:boolean=true) => {
        if(isCode) {
            return await Auth.sendCustomChallengeAnswer(data, password)
        }
        return await Auth.signIn({
            password: password,
            username: email
        })
    }, [data]);

    const SignOut = useCallback(async () => {
        await Auth.signOut()
    }, []);

    const IsAuthenticated = useCallback(async () => {
        try {
            await Auth.currentAuthenticatedUser()
            return true

        } catch (error) {
            return false
        }
    }, [])

    return {
        SignIn,
        SignOut,
        IsAuthenticated,
        Request
    }
}