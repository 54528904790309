const pt = {
    translation: {
        "schedule_option": "Agendar",
        "schedule": "Confirmar agendamento",
        "listschedule": "Lista de Agendamentos",
        "listschedule_title":"Meus agendamentos",
        "username_input": "CPF",
        "username_placeholder": "Insira seu CPF",
        "username_error": "Por favor, insira um CPF válido",
        "password_input": "Senha",
        "password_placeholder": "Insira sua senha",
        "password_error": "Por favor, insira uma senha válida",
        "code_input": "Código",
        "code_placeholder": "Insira o código enviado a você",
        "keep_logged": "Manter conectado",
        "login_submit": "Solicitar código",
        "go_to_page": "Ir para o atendimento",
        "cancel": "Cancelar",
        "select_modal_title": "Escolher um horário",
        "select_modal_body": "Qual o melhor horário para o atendimento?",
        "confirm": "Confirmar",
        "schedule_title": "Agendar minha consulta",
        "SCHEDULED":'AGENDADO',
        "CANCELED":'CANCELADO',
        "DONE":'CONCLUÍDO',
        "cancel_modal_title": "Cancelar",
        "cancel_text": "Tem certeza que deseja cancelar?",
        "exit": "Sair",
        "continue_cancel": "Cancelar",
        "terms_accept": "Li e aceitos os",
        "terms_conditions":"termos e condições de uso",
        "terms_divider": "e a",
        "terms_privacy":"politica de privacidade",
        "noschedule":"Escolher um horário",
        "already_taken":"Você já tem um horário agendado para ",
        "code_confirm":"Confirmar código",
        "password_confirm":"Confirmar senha",
        "password-signin":"Entrar com senha"

    }
};

export default pt;