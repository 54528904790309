import Index from '@/pages';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/styles/global.css';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import content from '@/content'

i18n.use(initReactI18next).init({
    resources:content,
    fallbackLng: 'pt-BR',
    interpolation:{
        escapeValue:false
    }

})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Index/>
    </React.StrictMode>
);

